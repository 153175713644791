import React from "react";
// @mui material components
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";

// Material Kit 2 PRO React examples
import TransparentBlogCard from "examples/Cards/BlogCards/TransparentBlogCard";

// Images
import image from "assets/images/products/product-1-min.jpg";
import Button from "components/MKButton";
import MKTypography from "components/MKTypography";

const AfterPic = ({ imgSrc, photoData, handleReset }) => {
  const { pictures_taken, remaining_pictures } = photoData || {};
  console.log("imgSrc", imgSrc);
  return (
    <MKBox pt={4} pb={3} px={3} width="100vw" height="100vh" maxWidth="100%" maxHeight="100%">

        <MKBox position="relative" borderRadius="lg">
          <MKBox
            component="img"
            src={imgSrc}
            borderRadius="lg"
            shadow="md"
            width="70%"
            height="70%"
            position="relative"
            zIndex={1}
          />
        </MKBox>

        <MKTypography variant="h5" gutterBottom>
            {remaining_pictures == null ? "Loading" : `Puedes tomar ${remaining_pictures} fotos más`}
        </MKTypography>
        <MKTypography variant="body2" component="p" color="text" mb={3}>
            {`Fotos tomadas: ${pictures_taken || 0}`}
        </MKTypography>

        {remaining_pictures ? (
            <Button onClick={handleReset}>Toma otra Foto({remaining_pictures})</Button>
        ) : (
            <Button>No Puedes tomar mas Fotos</Button>
      )}
    </MKBox>
  );
};

export default AfterPic;
