/**
 =========================================================
 * Material Kit 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-kit-pro-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import { useEffect, useState } from "react";

// react-router-dom components
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";

// Authentication pages components
import BasicLayout from "pages/Authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import { userNoPassRoute } from "../../../../api/Globals/Endpoints";

import { http } from "../../../../api/utils/Http";

import Cookies from "js-cookie";

// Function to create a user account
export const createUserNoPassword = async (userData) => {
  try {
    const response = await http.post(userNoPassRoute, userData);
    return response; // Assuming the response data contains relevant information
  } catch (error) {
    console.error("Error creating user:", error);
    throw error; // Rethrow the error to handle it in the calling component
  }
};

function SignInBasic() {
  const navigate = useNavigate();
  const [rememberMe, setRememberMe] = useState(false);
  const [userInfo, setUserInfo] = useState({ eventId: '', name: "", email: "" });
  const [eventPassed, setEventPassed] = useState(false);

  useEffect(() => {
    setUserInfo({ ...userInfo, eventId: localStorage.getItem('eventId') })
    setEventPassed(localStorage.getItem('eventPassed') == 'true');
  }, [])

  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  // Handle the input change
  const handleOnChange = (event) => {
    const { name, value } = event.target;

    // Convert the value to lowercase if the input name is 'email'
    const processedValue = name === "email" ? value.toLowerCase() : value;

    setUserInfo({ ...userInfo, [name]: processedValue });
  };

  const handleSubmit = (event) => {
    if (eventPassed == true) {
      console.log("userinfo before changing to pictures:", userInfo);
      localStorage.setItem('userInfo', JSON.stringify(userInfo));
      navigate("/pictures");}
    else
        handleCreateUser();
  };

  const handleCreateUser = async () => {
    try {
      // Prepare the user data object
      // const userData = {"email": userEmail, "name": userName};

      // Log userData for debugging
      console.log("Sending userData to server:", userInfo);

      // Call the user creation function
      const response = await createUserNoPassword(userInfo);
      if (response.status === 200 || response.status === 201) {
        // Update the userInfo state with the response data
        const updatedUserInfo = {
          ...userInfo,
          ...response.data, // Merge response data with existing userInfo
        };

        // Save updated userInfo to localStorage
        localStorage.setItem('userInfo', JSON.stringify(updatedUserInfo));
        // Log the updated userInfo to confirm the merge
        console.log("Updated userInfo: ", updatedUserInfo);

        // Use updatedUserInfo for conditional navigation
        if (updatedUserInfo.eventUser_pics_remaining > 0) {
          navigate("/photo");
        } else {
          navigate("/profile");
        }
      }

      // ... rest of your success handling code ...
    } catch (error) {
      // Log the entire error for debugging
      console.error("Error in handleCreateUser:", error);
      // ... rest of your error handling code ...
    }
  };

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MKBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MKTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            {eventPassed ? "Ver fotos" : "Para recibir mañana las fotos"}
          </MKTypography>
        </MKBox>
        <MKBox pt={4} pb={3} px={3}>
          <MKBox component="form" role="form">
            {!eventPassed && (
            <MKBox mb={2}>
              <MKInput
                type="text"
                name="name"
                label="Name"
                fullWidth
                value={userInfo.name} // Bind the input value to the state
                onChange={handleOnChange} // Update the state on change
              />
            </MKBox>
                )}
            <MKBox mb={2}>
              <MKInput
                type="email"
                name="email"
                label="Email"
                fullWidth
                value={userInfo.email} // Bind the input value to the state
                onChange={handleOnChange} // Update the state on change
              />
            </MKBox>
            <MKBox display="flex" alignItems="center" ml={-1}>
              <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <MKTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Recordarme
              </MKTypography>
            </MKBox>
            <MKBox mt={4} mb={1}>
              <MKButton
                variant="gradient"
                color="info"
                fullWidth
                onClick={handleSubmit}
              >
                {eventPassed ? "Ver fotos" : "Toma Fotos"}
              </MKButton>
            </MKBox>
            {/*<MKBox mt={3} mb={1} textAlign="center">*/}
            {/*  <MKTypography variant="button" color="text">*/}
            {/*    Don&apos;t have an account?{" "}*/}
            {/*    <MKTypography*/}
            {/*      component={Link}*/}
            {/*      to="/authentication/sign-up/cover"*/}
            {/*      variant="button"*/}
            {/*      color="info"*/}
            {/*      fontWeight="medium"*/}
            {/*      textGradient*/}
            {/*    >*/}
            {/*      Sign up*/}
            {/*    </MKTypography>*/}
            {/*  </MKTypography>*/}
            {/*</MKBox>*/}
          </MKBox>
        </MKBox>
      </Card>
    </BasicLayout>
  );
}

export default SignInBasic;
