/**
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import {Fragment, useState} from "react";

// react-router components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import MuiLink from "@mui/material/Link";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

import {saveAs} from "file-saver";

function SimpleBookingCard({ image, title, description, categories, action, onClick }) {
    // Function to handle direct image download
    // const handleDownload = async (url, filename = 'image') => {
    //     try {
    //         console.log("Trying to download");
    //         const response = await fetch(url);
    //         const blob = await response.blob();
    //         const downloadUrl = window.URL.createObjectURL(blob);
    //         const link = document.createElement('a');
    //         link.href = downloadUrl;
    //         link.setAttribute('download', filename);
    //         document.body.appendChild(link);
    //         link.click();
    //         link.parentNode.removeChild(link);
    //     } catch (error) {
    //         console.error('Error downloading file:', error);
    //     }
    // };
    // const [fetching, setFetching] = useState(false);
    // const [error, setError] = useState(false);
    //
    // const download = (url, name) => {
    //     if (!url) {
    //         throw new Error("Resource URL not provided! You need to provide one");
    //     }
    //     setFetching(true);
    //     fetch(url)
    //         .then(response => response.blob())
    //         .then(blob => {
    //             setFetching(false);
    //             const blobURL = URL.createObjectURL(blob);
    //             const a = document.createElement("a");
    //             a.href = blobURL;
    //             a.style = "display: none";
    //
    //             if (name && name.length) a.download = name;
    //             document.body.appendChild(a);
    //             a.click();
    //         })
    //         .catch(() => setError(true));
    // };
    const handleClick = (url, name)=>{
        saveAs(url, name);
    }
    // Function to determine the action button based on action.type
    const renderActionButton = () => {
        switch (action.type) {
            case 'internal':
                return (
                    <MKButton
                        component={Link}
                        to={action.route}
                        variant="outlined"
                        size="small"
                        color={action.color ? action.color : "dark"}
                    >
                        {action.label}
                    </MKButton>
                );
            case 'external':
                return (
                    <MKButton
                        component={MuiLink}
                        href={action.route}
                        target="_blank"
                        rel="noreferrer"
                        variant="outlined"
                        size="small"
                        color={action.color ? action.color : "dark"}
                    >
                        {action.label}
                    </MKButton>
                );
            case 'download':
                // Assuming handleDownload is defined elsewhere in your component
                // to handle the download logic
                return (
                    <MKButton
                        onClick={() => handleClick(action.route, title.replace(/\s+/g, '-').toLowerCase())}
                        variant="outlined"
                        size="small"
                        color={action.color || "dark"}
                    >
                        {action.label}
                    </MKButton>
                );
            default:
                return null; // or some default action
        }
    };
    return (
        <Card>
            <MKBox position="relative" borderRadius="lg" mx={2} mt={-3}>
                <MKBox
                    component="img"
                    src={image}
                    alt={title}
                    borderRadius="lg"
                    shadow="md"
                    width="100%"
                    position="relative"
                    zIndex={1}
                />
                <MKBox
                    borderRadius="lg"
                    shadow="md"
          width="100%"
          height="100%"
          position="absolute"
          left={0}
          top={0}
          sx={{
            backgroundImage: `url(${image})`,
            transform: "scale(0.94)",
            filter: "blur(12px)",
            backgroundSize: "cover",
          }}
        />
      </MKBox>
      <MKBox p={3} mt={-2}>
        {categories.length > 0 && (
          <MKTypography
            display="block"
            variant="button"
            color="text"
            fontWeight="regular"
            mb={0.75}
          >
            {categories.map((category) => (
              <Fragment key={category}>{category}&nbsp;&bull;&nbsp;</Fragment>
            ))}
          </MKTypography>
        )}
        <MKTypography display="inline" variant="h5" fontWeight="bold">
          {title}
        </MKTypography>
        <MKBox mt={1} mb={3}>
          <MKTypography variant="body2" component="p" color="text">
            {description}
          </MKTypography>
        </MKBox>
          {renderActionButton()}
      </MKBox>
    </Card>
  );
}

// Setting default props for the SimpleBookingCard
SimpleBookingCard.defaultProps = {
  categories: [],
};

// Typechecking props for the SimpleBookingCard
SimpleBookingCard.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  categories: PropTypes.arrayOf(PropTypes.string),
  action: PropTypes.shape({
    type: PropTypes.oneOf(["external", "internal", "download"]).isRequired,
    route: PropTypes.string.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
    ]),
    label: PropTypes.string.isRequired,
  }).isRequired,
  onClick: PropTypes.func,
};

export default SimpleBookingCard;
