// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

// Images
import bgImage from "assets/images/examples/blog2.jpg";
import {useState} from "react";
import {createUserNoPassword} from "../../Authentication/SignIn/BasicNoPass";
import {useNavigate} from "react-router-dom";
import {http} from "../../../api/utils/Http";
import {sendContactEmail} from "../../../api/Globals/Endpoints";
import MKAlert from "../../../components/MKAlert";

export const sendContactEmailFunc = async (userData) => {
    try {
        const response = await http.post(sendContactEmail, userData);
        return response; // Assuming the response data contains relevant information
    } catch (error) {
        console.error("Error sending contact:", error);
        throw error; // Rethrow the error to handle it in the calling component
    }
};

function ContactUsTwo() {
    const [userInfo, setUserInfo] = useState({messageFromCustomer: '', name: "", email: ""});
    const [alert, setAlert] = useState({show: false, message: ''});
    const navigate = useNavigate();

    const handleCloseAlert = () => {
        setAlert({...alert, show: false});
    };

    const handleOnChange = (event) => {
        const target = event.target;
        setUserInfo({...userInfo, [target.name]: target.value});
    };

    const isValidEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(email.toLowerCase());
    };
    const handleContactEvent = async () => {
        if (!isValidEmail(userInfo.email)) {
            setAlert({show: true, message: "Ingresa un email válido"});
            return;
        }
        if (!userInfo.name) {
            setAlert({show: true, message: "Ingresa tu nombre"});
            return;
        }
        if (!userInfo.messageFromCustomer) {
            setAlert({show: true, message: "Ingresa un mensaje"});
            return;
        }
        try {
            // Prepare the user data object
            // const userData = {"email": userEmail, "name": userName};

            // Log userData for debugging
            console.log("Sending userData to server:", userInfo);

            // Call the user creation function
            const response = await sendContactEmailFunc(userInfo);
            if (response.status === 200 || response.status === 201) {
                localStorage.setItem('userInfo', JSON.stringify(userInfo))
                navigate("/");
            }

            // ... rest of your success handling code ...
        } catch (error) {
            // Log the entire error for debugging
            console.error("Error in handleSendContactEmail:", error);
            // ... rest of your error handling code ...
        }
    };

    return (
        <MKBox component="section" py={{xs: 0, lg: 6}}>
            <Container>
                <Grid item xs={12}> {/* Full width grid item for the alert */}
                    {alert.show && (
                        <MKAlert color="warning" dismissible onDismiss={handleCloseAlert}>
                            {alert.message}
                        </MKAlert>
                    )}
                </Grid>
                <Grid container item px={6}>
                    <MKBox
                        width="100%"
                        bgColor="white"
                        borderRadius="xl"
                        shadow="xl"
                        mb={6}
                        sx={{overflow: "hidden"}}
                    >
                        <Grid container spacing={3}>
                            <Grid item xs={12} lg={7}>
                                <MKBox component="form" p={2} method="post">
                                    <MKBox px={3} py={{xs: 2, sm: 6}}>
                                        <MKTypography variant="h2" mb={1}>
                                            Hola!
                                        </MKTypography>
                                        <MKTypography variant="body1" color="text" mb={2}>
                                            Nos gustaría hablar contigo
                                        </MKTypography>
                                    </MKBox>
                                    <MKBox pt={0.5} pb={3} px={3}>
                                        <Grid container>
                                            <Grid item xs={12} pr={1} mb={3}>
                                                <MKInput
                                                    variant="standard"
                                                    label="Mi nombre es"
                                                    name="name"
                                                    placeholder="Nombre completo"
                                                    value={userInfo.name} // Bind the input value to the state
                                                    onChange={handleOnChange} // Update the state on change
                                                    InputLabelProps={{shrink: true}}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={12} pr={1} mb={3}>
                                                <MKInput
                                                    variant="standard"
                                                    type="email"
                                                    name="email"
                                                    label="Mi correo es"
                                                    placeholder="Correo electrónico"
                                                    value={userInfo.email} // Bind the input value to the state
                                                    onChange={handleOnChange} // Update the state on change
                                                    InputLabelProps={{shrink: true}}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={12} pr={1} mb={3}>
                                                <MKInput
                                                    variant="standard"
                                                    label="Tu mensaje"
                                                    name="messageFromCustomer"
                                                    placeholder="Me gustaría decirles que ..."
                                                    value={userInfo.messageFromCustomer} // Bind the input value to the state
                                                    onChange={handleOnChange} // Update the state on change
                                                    InputLabelProps={{shrink: true}}
                                                    fullWidth
                                                    multiline
                                                    rows={6}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            container
                                            item
                                            xs={12}
                                            md={6}
                                            justifyContent="flex-end"
                                            textAlign="right"
                                            ml="auto"
                                        >
                                            <MKButton variant="gradient" color="info" onClick={handleContactEvent}>
                                                Mandar Mensaje
                                            </MKButton>
                                        </Grid>
                                    </MKBox>
                                </MKBox>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                lg={5}
                                position="relative"
                                px={0}
                                sx={{
                                    backgroundImage: ({
                                                          palette: {gradients},
                                                          functions: {rgba, linearGradient},
                                                      }) => `${linearGradient(rgba(gradients.info.main, 0.8), rgba(gradients.info.state, 0.8))}, url(${bgImage})`,
                                    backgroundSize: "cover",
                                }}
                            >
                                <MKBox
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    width="100%"
                                    height="100%"
                                >
                                    <MKBox py={6} pl={6} pr={{xs: 6, sm: 12}} my="auto">
                                        <MKTypography variant="h3" color="white" mb={1}>
                                            Contacto
                                        </MKTypography>
                                        <MKTypography variant="body2" color="white" opacity={0.8} mb={3}>
                                            Llena la información y estaremos contestandote en las siguientes 24 horas.
                                        </MKTypography>
                                        <MKBox display="flex" color="white" p={1}>
                                            <MKTypography variant="button" color="white">
                                                <i className="fas fa-envelope"/>
                                            </MKTypography>
                                            <MKTypography
                                                component="span"
                                                variant="button"
                                                color="white"
                                                opacity={0.8}
                                                ml={2}
                                                fontWeight="regular"
                                            >
                                                hola@fotix.io
                                            </MKTypography>
                                        </MKBox>
                                    </MKBox>
                                </MKBox>
                            </Grid>
                        </Grid>
                    </MKBox>
                </Grid>
            </Container>
        </MKBox>
    );
}

export default ContactUsTwo;
