/*
@copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author    : Shiv Charan Panjeta < shiv@toxsl.com >

All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/

const api = {
  // platform: "http://127.0.0.1:8000/",
  // platform: "https://rtix.rocko.me/"
  platform: 'https://fotix.rocko.me/'
};

let apiBase = api.platform;

export default apiBase;
