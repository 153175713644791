/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

function LifetimeMembership() {
  return (
    <MKBox component="section" py={{ xs: 12, lg: 18 }}>
      <Container>
        <Grid
          container
          item
          xs={12}
          md={6}
          justifyContent="center"
          sx={{ mx: "auto", mb: 8, textAlign: "center" }}
        >
          <MKTypography variant="h2" mb={1}>
            Evento más grande?
          </MKTypography>
          <MKTypography variant="body1" color="text">
            Si tienes un evento más grande nos puedes contactar para poder ofrecerte un mejor precio!
          </MKTypography>
        </Grid>
        <Grid container item xs={12}>
          <Card sx={{ width: "100%" }}>
            <Grid container alignItems="center">
              <Grid item xs={12} lg={8}>
                <MKBox py={3} px={4}>
                  <MKTypography variant="h3" mb={1}>
                    Super Evento
                  </MKTypography>
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                    Si tienes un evento más grande donde la calidad y los recuerdos inolvidables son esenciales,
                    nuestros paquetes ofrecen fotos ilimitadas con soporte premium.
                  </MKTypography>
                  <Grid container item xs={12} lg={3} sx={{ mt: 6, mb: 1 }}>
                    <MKTypography variant="h6">Qué Incluye</MKTypography>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <MKBox display="flex" py={1} pr={1} lineHeight={0}>
                        <MKTypography variant="body1" color="dark">
                          <Icon sx={{ fontWeight: "bold" }}>done</Icon>
                        </MKTypography>
                        <MKTypography variant="body2" color="text" fontWeight="regular" pl={1}>
                          Código QR único
                        </MKTypography>
                      </MKBox>
                      <MKBox display="flex" py={1} pr={1} lineHeight={0}>
                        <MKTypography variant="body1" color="dark">
                          <Icon sx={{ fontWeight: "bold" }}>done</Icon>
                        </MKTypography>
                        <MKTypography variant="body2" color="text" fontWeight="regular" pl={1}>
                          Fotos ilimitadas
                        </MKTypography>
                      </MKBox>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MKBox display="flex" py={1} pr={1} lineHeight={0}>
                        <MKTypography variant="body1" color="dark">
                          <Icon sx={{ fontWeight: "bold" }}>done</Icon>
                        </MKTypography>
                        <MKTypography variant="body2" color="text" fontWeight="regular" pl={1}>
                          Galería de fotos por evento
                        </MKTypography>
                      </MKBox>
                      <MKBox display="flex" py={1} pr={1} lineHeight={0}>
                        <MKTypography variant="body1" color="dark">
                          <Icon sx={{ fontWeight: "bold" }}>done</Icon>
                        </MKTypography>
                        <MKTypography variant="body2" color="text" fontWeight="regular" pl={1}>
                          Streaming de Fotos de evento
                        </MKTypography>
                      </MKBox>
                    </Grid>
                  </Grid>
                </MKBox>
              </Grid>
              <Grid item xs={12} lg={4}>
                <MKBox p={3} textAlign="center">
                  <MKTypography variant="h6" mt={{ xs: 0, sm: 3 }}>
                    Paga por Usuario
                  </MKTypography>
                  <MKTypography variant="h1">
                    <MKBox component="small">$1.5/Invitado</MKBox>
                  </MKTypography>
                  <MKButton variant="gradient" color="error" size="large" sx={{ my: 2 }}>
                    Contactar
                  </MKButton>
                  <MKTypography display="block" variant="button" color="text" fontWeight="regular">
                    Arriba de 500 invitados
                  </MKTypography>
                </MKBox>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default LifetimeMembership;
