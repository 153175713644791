/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import {useRef} from "react";

// SwiperJS
import SwiperCore, {Autoplay, Navigation} from "swiper";

// SwiperJS react components
import {Swiper, SwiperSlide} from "swiper/react";

// SwiperJS styles
import "swiper/swiper.min.css";
import "swiper/css/navigation";

// @mui material components
import Container from "@mui/material/Container";
import Icon from "@mui/material/Icon";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import ComplexReviewCard from "examples/Cards/ReviewCards/ComplexReviewCard";

// Images
import review1Image from "assets/images/examples/couple.jpg";
import review2Image from "assets/images/examples/fireworks.jpg";
import review3Image from "assets/images/examples/family.jpg";

function Testimonials() {
    // install SwiperJS modules
    SwiperCore.use([Autoplay, Navigation]);

    // Swiper navigation buttons styles
    const navigationStyles = {
        position: "absolute",
        top: 0,
        zIndex: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "15%",
        height: "100%",
        textAlign: "center",
        opacity: 0.5,
        cursor: "pointer",
        transition: "opacity 0.15s ease",

        "&:hover, &:focus": {
            opacity: 1,
        },
    };

    // SwiperJS navigation buttons ref
    const navigationPrevRef = useRef(null);
    const navigationNextRef = useRef(null);

    return (
        <MKBox py={8} position="relative">
            <Swiper
                onInit={({params, navigation}) => {
                    const {navigation: nav} = params;

                    nav.prevEl = navigationPrevRef.current;
                    nav.nextEl = navigationNextRef.current;
                    navigation.init();
                    navigation.update();
                }}
                autoplay={{delay: 5000}}
                speed={800}
                spaceBetween={0}
                slidesPerView={1}
                loop
            >
                <SwiperSlide>
                    <Container>
                        <ComplexReviewCard
                            image={review3Image}
                            title="Una experiencia única en nuestro evento familiar"
                            review="La aplicación transformó nuestra reunión familiar en un recuerdo inolvidable. Cada foto capturada contó una historia especial, y la facilidad de uso fue increíble. Realmente capturó la magia del momento."
                            author={{
                                name: "Laura Hernández",
                                role: "Organizadora de Eventos Familiares",
                            }}
                        />

                    </Container>
                </SwiperSlide>
                <SwiperSlide>
                    <Container>
                        <ComplexReviewCard
                            image={review2Image}
                            title="La mejor herramienta para nuestro evento corporativo"
                            review="Utilizamos la app en nuestra última conferencia y fue un éxito total. Permitió a los asistentes capturar y compartir momentos espontáneos, mejorando notablemente la interacción y el ambiente general del evento."
                            author={{
                                name: "Carlos Ruiz",
                                role: "Coordinador de Eventos - TechCorp",
                            }}
                        />
                    </Container>
                </SwiperSlide>
                <SwiperSlide>
                    <Container>
                        <ComplexReviewCard
                            image={review1Image}
                            title="Imprescindible para bodas y celebraciones"
                            review="Para nuestra boda, decidimos usar esta app y no podríamos estar más felices con los resultados. Los invitados disfrutaron tomando sus propias fotos y la calidad de las imágenes fue excepcional. ¡Un recuerdo perfecto de nuestro día especial!"
                            author={{
                            name: "Sofía y Andrés",
                            role: "Recién Casados",
                        }}
                            />

                    </Container>
                </SwiperSlide>
                <MKTypography
                    variant="h2"
                    color="dark"
                    sx={{
                        ...navigationStyles,
                        left: 0,
                    }}
                    ref={navigationPrevRef}
                >
                    <Icon>chevron_left</Icon>
                </MKTypography>
                <MKTypography
                    variant="h2"
                    color="dark"
                    sx={{
                        ...navigationStyles,
                        right: 0,
                    }}
                    ref={navigationNextRef}
                >
                    <Icon>chevron_right</Icon>
                </MKTypography>
            </Swiper>
        </MKBox>
    );
}

export default Testimonials;
