/**
 =========================================================
 * Material Kit 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-kit-pro-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import { useEffect, useState } from "react";

// react-router-dom components
import { useNavigate, useSearchParams } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKAlert from "components/MKAlert";

// Authentication pages components
import BasicLayout from "pages/Authentication/components/BasicLayout";

import Cookies from 'js-cookie';

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import { checkEvent } from "../../../../api/Globals/Endpoints";

import { http } from "../../../../api/utils/Http";

// Function to create a user account
export const checkEventPost = async (userData) => {
    try {
        const response = await http.post(checkEvent, userData);
        return response // Assuming the response data contains relevant information
    } catch (error) {
        if (error.response) {
            // Handle HTTP errors
            return error.response;
        } else {
            // Handle other errors (network error, etc.)
            throw error;
        }
    }
};


function EventSignIn() {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams()
    const [eventNo, setEventNo] = useState(searchParams.get('event_id') ? searchParams.get('event_id') : '');
    const [alert, setAlert] = useState({ show: false, message: '' });

    useEffect(() => {
        if (searchParams.get('event_id')) {
            handleSignInEvent()
        }
    }, [searchParams])

    const handleEventChange = (event) => {
        let input = event.target.value;
        const alphanumericOnly = input.replace(/[^a-z0-9]/gi, ''); // Remove non-alphanumeric characters

        // Limit the length of alphanumeric characters to 9 (xxx-xxx-xxx)
        const trimmedInput = alphanumericOnly.substring(0, 9);

        let formattedInput = '';

        // Loop through the characters and add hyphens after every 3rd alphanumeric character
        for (let i = 0; i < trimmedInput.length; i++) {
            if (i !== 0 && i % 3 === 0) { // Add hyphen after every 3 characters
                formattedInput += '-';
            }
            formattedInput += trimmedInput[i];
        }


        setEventNo(formattedInput);
    };

    const handleCloseAlert = () => {
        setAlert({ ...alert, show: false });
    };


    const handleSignInEvent = async () => {
        try {

            // Prepare the user data object
            const eventData = { "event_id": eventNo };

            // Log userData for debugging
            console.log('Sending eventData to server:', eventData);

            // Call the user creation function
            const response = await checkEventPost(eventData);
            // Handle different response statuses
            console.log("status response:", response.status);
            if (response.status === 200) {
                // Save eventData to a cookie
                localStorage.setItem('eventId', eventNo)
                localStorage.setItem('eventPassed', false)
                navigate('/authentication/sign-in/basic');
            } else if (response.status === 226) {
                // Event already passed
                localStorage.setItem('eventId', eventNo)
                localStorage.setItem('eventPassed', true)
                navigate('/authentication/sign-in/basic');
            } else if (response.status === 403) {
                // Event is not ongoing
                setAlert({ show: true, message: "Event is not ongoing." });
            } else if (response.status === 404) {
                setAlert({ show: true, message: "Event does not exist." });
            } else if (response.status === 400) {
                setAlert({ show: true, message: "Event ID is required." });
            } else {
                setAlert({ show: true, message: "An unexpected error occurred." });
            }
        } catch (error) {
            console.error("Error in handleSignInEvent:", error);
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            } else if (error.request) {
                // The request was made but no response was received
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }
        }
    };

    return (
        <BasicLayout image={bgImage}>
            {alert.show && (
                <MKAlert color="warning" dismissible onDismiss={handleCloseAlert}>
                    {alert.message}
                </MKAlert>
            )}
            <Card>
                <MKBox
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                    mx={2}
                    mt={-3}
                    p={2}
                    mb={1}
                    textAlign="center"
                >
                    <MKTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                        Evento
                    </MKTypography>
                </MKBox>
                <MKBox pt={4} pb={3} px={3}>
                    <MKBox component="form" role="form">
                        <MKBox mb={2}>
                            <MKInput type="input"  label="Clave de Evento" fullWidth
                                value={eventNo} // Bind the input value to the state
                                onChange={handleEventChange} // Update the state on change
                            />
                        </MKBox>
                        <MKBox mt={4} mb={1}>
                            <MKButton variant="gradient" color="info" fullWidth onClick={handleSignInEvent}>
                                Entrar
                            </MKButton>
                        </MKBox>
                        {/*<MKBox mt={3} mb={1} textAlign="center">*/}
                        {/*  <MKTypography variant="button" color="text">*/}
                        {/*    Don&apos;t have an account?{" "}*/}
                        {/*    <MKTypography*/}
                        {/*      component={Link}*/}
                        {/*      to="/authentication/sign-up/cover"*/}
                        {/*      variant="button"*/}
                        {/*      color="info"*/}
                        {/*      fontWeight="medium"*/}
                        {/*      textGradient*/}
                        {/*    >*/}
                        {/*      Sign up*/}
                        {/*    </MKTypography>*/}
                        {/*  </MKTypography>*/}
                        {/*</MKBox>*/}
                    </MKBox>
                </MKBox>
            </Card>
        </BasicLayout>
    );
}

export default EventSignIn;
