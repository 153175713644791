/**
 =========================================================
 * Material Kit 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-kit-pro-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import {useEffect} from "react";

// react-router components
import {Routes, Route, useLocation} from "react-router-dom";

// @mui material components
import {ThemeProvider} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Kit 2 PRO React themes
import theme from "assets/theme";


// Material Kit 2 PRO React routes
import routes from "routes";
import SignInBasicPage from "./layouts/authentication/sign-in/basic";
import HomePath from "./pages/Home/Home";
import TakePhoto from "./pages/TakePhoto/TakePhoto";
import ContactUs from "./pages/Support/ContactUs";
import Pictures from "./pages/ShowPics";

export default function App() {
    const {pathname} = useLocation();

    // Setting page scroll to 0 when changing the route
    useEffect(() => {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
    }, [pathname]);

    const getRoutes = (allRoutes) =>
        allRoutes.map((route) => {
            if (route.collapse) {
                return getRoutes(route.collapse);
            }

            if (route.route) {
                return <Route exact path={route.route} element={route.component} key={route.key}/>;
            }

            return null;
        });

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <Routes>
                {getRoutes(routes)}
                <Route path="/" element={<HomePath/>}/>
                <Route path="/authentication/sign-in/basic" element={<SignInBasicPage/>}/>
                <Route path="*" element={<SignInBasicPage to="/authentication/sign-in/basic"/>}/>
                <Route path="/photo" element={<TakePhoto/>}/>
                <Route path="/contact" element={<ContactUs/>}/>
                <Route path="/pictures" element={<Pictures/>}/>
            </Routes>
        </ThemeProvider>
    );
}
