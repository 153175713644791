import React, { useState, useRef } from "react";
import axios from "axios";
import "react-html5-camera-photo/build/css/index.css";
import Cookies from "js-cookie";
import { getS3Url, savedPictureToS3 } from "../../../api/Globals/Endpoints";
import { http } from "../../../api/utils/Http";
import AfterPic from "./afterpic";
import MKButton from "../../../components/MKButton";
import bgImage from "../../../assets/images/dg3.jpg";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKTypography from "../../../components/MKTypography";
import MKBox from "../../../components/MKBox";

const TakePhoto = () => {
  const [userInfo, setUserInfo] = useState(JSON.parse(localStorage.getItem('userInfo')));
  const [imgSrc, setImgSrc] = useState(null); // State to store image source
  const [isPhotoTaken, setIsPhotoTaken] = useState(false);
  const [photoData, setPhotoData] = useState(null); // State to store photo data

  const handleImageChange = async (event) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];

      // Convert the selected file to a data URL for display
      const reader = new FileReader();
      reader.onload = (e) => {
        setImgSrc(e.target.result);
        console.log("Image Source:", e.target.result)
        const imgUpload = handleImageUpload(e.target.result); // Upload image after capture
        if (imgUpload) {
          setIsPhotoTaken(true); // Update state to indicate photo is taken
        }
      };
      reader.readAsDataURL(file);
    }
  };
  // Create a ref for the file input
  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    // Check if the file input ref is current and trigger a click
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  async function handleImageUpload(dataUri) {
    try {
      // Make the POST request with the JSON payload
      const s3UrlResponse = await http.get(
          getS3Url(userInfo.eventId, userInfo.email),
          {}
      )

      console.log("S3 URL Response:", s3UrlResponse);

      // Convert Data URI to Blob for S3 upload
      const file = dataUriToBlob(dataUri);
      const payload = {
        event_id: userInfo.eventId,
        user_id: userInfo.email,
        photo_uuid: s3UrlResponse.data.uuid,
        url: s3UrlResponse.data.presigned_post,
      };
      const uploadImage = await uploadImageToS3(
          file,
          s3UrlResponse.data.presigned_post
      );
      if (uploadImage.status === 200) {
        const savePictureResponse = await http.post(
            savedPictureToS3,
            JSON.stringify(payload),
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
        );
        console.log("Save Picture Response:", savePictureResponse);

        if (savePictureResponse.status === 201) {
          console.log("Picture saved successfully.");
          setPhotoData(savePictureResponse.data.data); // Save the response data
          return true;
          // Handle successful picture saving logic here
        } else if (savePictureResponse.status === 403) {
          console.log(
              "Error: You have already taken all the pictures for this event"
          );
          setPhotoData({ remaining_pictures: 0, pictures_taken: 'N/A' })
          // Handle error for max pictures taken
        } else if (savePictureResponse.status === 404) {
          console.log("Error: Event or User not found");
          // Handle error for event or user not found
        } else if (savePictureResponse.status === 400) {
          console.log("Error: " + savePictureResponse.data.error);
          // Handle bad request errors
        } else {
          console.error("Unknown error occurred");
          // Handle other unexpected errors
        }
      }

      console.log("File uploaded successfully.");
    } catch (error) {
      setPhotoData({ remaining_pictures: 0, pictures_taken: 'N/A' })
      console.error("Error in handleImageUpload:", error);
    }
  }

  function dataUriToBlob(dataUri) {
    const byteString = atob(dataUri.split(",")[1]);
    const mimeString = dataUri.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  }

  async function uploadImageToS3(file, presignedUrl) {
    try {
      // Axios PUT request
      const response = await axios({
        method: "PUT",
        url: presignedUrl,
        data: file, // Send the file directly
        headers: {
          "Content-Type": file.type, // Set the file's content type
        },
      });

      if (response.status !== 200) {
        // Status code for a successful PUT request is usually 200
        throw new Error("Upload failed");
      }
      return response;
    } catch (error) {
      console.error("Error uploading to Server:", error);
      throw error;
    }
  }

  // Function to handle resetting the component to take another photo
  const handleReset = () => {
    setIsPhotoTaken(false);
    setImgSrc(null);
  };

  return (
      <div>
        {!isPhotoTaken && (
            <>
              <MKBox
                  minHeight="100vh"
                  width="100%"
                  sx={{
                    backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
                        `${linearGradient(
                            rgba(gradients.dark.main, 0.6),
                            rgba(gradients.dark.state, 0.6)
                        )}, url(${bgImage})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    display: "grid",
                    placeItems: "center",
                  }}
              >
                <Container>
                  <Grid
                      container
                      item
                      xs={12}
                      lg={8}
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="center"
                      sx={{mx: "auto", textAlign: "center"}}
                  >
                    <MKTypography
                        variant="h2"
                        color="white"
                        mb={2}
                        sx={({breakpoints, typography: {size}}) => ({
                          [breakpoints.down("md")]: {
                            fontSize: size["3xl"],
                          },
                        })}
                    >
                      Listo para Tomar una Foto?
                    </MKTypography>
                    <input
                        type="file"
                        accept="image/*"
                        capture="camera"
                        onChange={handleImageChange}
                        style={{display: 'none'}}
                        ref={fileInputRef}  // Set the ref here
                    />
                    <MKButton variant="gradient" color="info" onClick={handleButtonClick}>
                      Toma una Foto
                    </MKButton>
                  </Grid>
                </Container>
              </MKBox>
            </>
        )}
        {isPhotoTaken && (
            <AfterPic
                imgSrc={imgSrc}
                photoData={photoData}
                handleReset={handleReset}
            />
        )}
      </div>
  );
};

export default TakePhoto;
