
// authentation routes name
export const userSignupRoute = "/users/api/accounts/signup";
export const userLoginRoute = "/users/api/accounts/login";
export const refreshTokenRoute = "/users/api/token/refresh";

export const sendContactEmail = "/users/api/contact"

export const forgotPassword = "/users/api/accounts/forget_password_otp";
export const verifyOtp = "/users/api/accounts/forget_password_otp_verification";
export const resetPassword = "/users/api/accounts/forget_password_reset";

export const userNoPassRoute = "/users/api/acounts/create_user_np";

export const checkEvent = "/event/api/check_event/";
export function getS3Url(variableValue, variableValue2)  {
  return `/event/api/get_s3_url/?event_id=${variableValue}&user_email=${variableValue2}`
};
export const  savedPictureToS3 = "/event/api/saved_picture_to_s3/";

export const getEventPictures = "/event/api/get_event_pictures/";
export const AddVehicleRoute = "/users/api/questions/";
export const getUserProfileRoute = "/users/api/accounts/profile/";
export const updateProfileRoute = "/users/api/accounts/profile/update/";
export const deleteProfileRoute = "/users/api/accounts/profile/delete/";

export const homeScreenRoute = "/pods/api/list";
export const thingsList = "/things/api/things/";
export const excavatorsList = "/things/api/things/excavators/list/";
export const poolsList = "/things/api/things/pools/list/";
export function getThingsDetailsRoute(variableValue) {
  return `/things/api/${variableValue}`;
}
export function getThingsEditRoute(variableValue) {
  return `/things/api/${variableValue}/edit`;
}
export function getThingsDetailsGraphRoute(variableValue) {
  return `/things/api/${variableValue}/graph`;
}
export function getThingsDetailsReportRoute(variableValue) {
  return `/things/api/${variableValue}/report`;
}

export const peopleList = "/people/api/list/";

export const rentMain = "rents/api/list/";

export const rentRent = "/rents/api/main/";

export const getCustomer = "/customers/api/customers/list";

export const getContact = "/customers/api/contacts/list/";

export const createRent = "/rents/api/create/";
//
// export const openPodDoor = "/pods/api/pod/";
//
// export const usageHistory = "/pods/api/usage_history/";
// export const getUnreadNotification = "/users/api/accounts/unread_notification";
// export const markReadUnread = "/users/api/accounts/update_notification/";
// export const getLatestTermsRoute = "/users/api/accounts/terms/latest/";
//
// export const getDeviceInfo = "users/api/accounts/sessionsignin/";
//
// export const getSpotsInfo = "/spots/api/pod/";
// export const userVerificationPod = "/spots/api/verify/";
// export const userSetPod = "/spots/api/get_access";
