// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKPagination from "components/MKPagination";

// Material Kit 2 PRO React components
import SimpleBookingCard from "examples/Cards/BookingCards/SimpleBookingCard";

// Images
import {useEffect, useState} from "react";
import {http} from "../../api/utils/Http";
import {getEventPictures} from "../../api/Globals/Endpoints";
import routes from "../../routes";
import DefaultNavbar from "../../examples/Navbars/DefaultNavbar";
import SimpleFooter from "../../examples/Footers/SimpleFooter";

export const getPictures = async (userData) => {
    try {
        // Construct the query string
        const queryParams = new URLSearchParams(userData).toString();
        const urlWithParams = `${getEventPictures}?${queryParams}`;

        // Make the GET request
        const response = await http.get(urlWithParams);
        return response; // Assuming the response data contains relevant information
    } catch (error) {
        console.error("Error getting pictures:", error);
        throw error; // Rethrow the error to handle it in the calling component
    }
};

function Pictures() {
    const [userInfo, setUserInfo] = useState();
    const [pictures, setPictures] = useState([]);
    useEffect(() => {
        const storedUserInfo = localStorage.getItem('userInfo');
        if (storedUserInfo) {
            const parsedUserInfo = JSON.parse(storedUserInfo);
            setUserInfo(parsedUserInfo);
            handleGetPictures(parsedUserInfo);
        }
    }, []);

    const handleGetPictures = async (userInfo) => {
        try {
            // Prepare the user data object
            // const userData = {"email": userEmail, "name": userName};

            // Log userData for debugging
            console.log("Sending userData to server:", userInfo);

            // Call the user creation function
            const response = await getPictures({event_id: userInfo.eventId, email: userInfo.email});
            console.log("response from getPictures", response);
            if (response.status === 200 || response.status === 201) {
                setPictures(response.data.data);
            }

            // ... rest of your success handling code ...
        } catch (error) {
            // Log the entire error for debugging
            console.error("Error in handleGetPictures:", error);
        }
    };

    console.log(userInfo);

    return (
        <><DefaultNavbar
            routes={routes}
            brand={"Fotix Camera"}
            action={{
                type: "internal",
                route: "/pages/company/pricing",
                label: "Crea tu Evento",
                color: "info",
            }}

        />
            <div style={{height: '40px'}}></div>
            <MKBox component="section" py={3}>
                <Container>
                    <Grid container spacing={3} sx={{mt: 3}}>
                        {pictures.map((picture) => {
                            // Convert the date and format it
                            const date = new Date(picture.date); // Ensure 'picture.date' is the correct path
                            const localDate = date.toLocaleDateString([], {
                                year: 'numeric', month: 'numeric', day: 'numeric',
                                hour: '2-digit', minute: '2-digit'
                            });

                            return (
                                <Grid item xs={12} md={6} lg={4} key={picture.uuid}>
                                    <MKBox mt={3}>
                                        <SimpleBookingCard
                                            image={picture.url}
                                            title={picture.user_name}
                                            description={localDate}
                                            action={{
                                                type: "download",
                                                route: picture.url,
                                                color: "info",
                                                label: "Descargar",
                                            }}
                                        />
                                    </MKBox>
                                </Grid>
                            );
                        })}
                    </Grid>
                    <MKBox mt={5}>
                        <MKPagination>
                            <MKPagination item>
                                <Icon>keyboard_arrow_left</Icon>
                            </MKPagination>
                            <MKPagination item active>
                                1
                            </MKPagination>
                            <MKPagination item>
                                <Icon>keyboard_arrow_right</Icon>
                            </MKPagination>
                        </MKPagination>
                    </MKBox>
                </Container>
                <SimpleFooter company={{name: "Fotix", href: "https://fotix.io"}}
                              links={[
                                  {href: "/about", name: "About Fotix"},
                                  {href: "/contact", name: "Contact"},
                              ]}/>
            </MKBox>

        </>
    );
}

export default Pictures;
