import axios from "axios";
import apiBase from "../Globals/Config";
import { refreshTokenRoute, userLoginRoute } from "../Globals/Endpoints";
import jwt from "jwt-decode";

/**Create an instance of axios with a custom config */

export const http = axios.create({
  baseURL: apiBase,
  headers: { "Content-Type": "application/json", Accept: "application/json" },
  withCredentials: true,
});

// Function to refresh the token
const refreshToken = async (refreshToken) => {
  try {
    const response = await http.post(refreshTokenRoute, {
      headers: { "Content-Type": "application/json" },
    });
    return response.data.access; // Assuming the new access token is returned here
  } catch (error) {
    // Handle any errors, such as token expiration or network issues
    console.error("Error refreshing token:", error);
    return null;
  }
};

// Response interceptor
http.interceptors.response.use(
  (response) => response, // this is for all successful responses
  async (error) => {
    const originalRequest = error.config;

    // Check if the response status is 401 and this request hasn't been retried yet
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true; // mark this request as already retried

      try {
        const newAccessToken = await refreshToken();
        // Update the header of the original request
        originalRequest.headers["Authorization"] = "Bearer " + newAccessToken;
        // Retry the request with the new token
        return http(originalRequest);
      } catch (refreshError) {
        // Handle failed refresh here (e.g., redirect to login)
        return Promise.reject(refreshError);
      }
    }

    // If the error is not due to a 401 or the refresh already failed, forward the error
    return Promise.reject(error);
  }
);
